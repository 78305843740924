<template>
    <svg
        id="icon_menu_rule"
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="25"
        viewBox="0 0 19 25"
    >
        <path
            id="Subtraction_42"
            data-name="Subtraction 42"
            d="M-5395-144h-13a3,3,0,0,1-3-3v-18.993a3,3,0,0,0,3,2.993h16v16A3,3,0,0,1-5395-144Zm-8.423-4.738h0V-148h4.5v-.739h-.414a1.711,1.711,0,0,1-.792-.152c-.191-.1-.288-.352-.288-.747v-7.056h-.649a6.31,6.31,0,0,1-2.357.755v.721h1.53v5.166a1.819,1.819,0,0,1-.208,1.07c-.135.161-.459.243-.962.243Zm2.25-11.736a1.014,1.014,0,0,0-.729.288.965.965,0,0,0-.3.72.993.993,0,0,0,.3.729,1,1,0,0,0,.729.3.974.974,0,0,0,.711-.3,1,1,0,0,0,.3-.729.975.975,0,0,0-.3-.71A.975.975,0,0,0-5401.174-160.474Z"
            transform="translate(5411 169)"
            fill="#ffe0a7"
        />
        <path
            id="Rectangle_577"
            data-name="Rectangle 577"
            d="M2.5,0h13A2.5,2.5,0,0,1,18,2.5V5a0,0,0,0,1,0,0H2.5A2.5,2.5,0,0,1,0,2.5v0A2.5,2.5,0,0,1,2.5,0Z"
            transform="translate(1)"
            fill="#ffe0a7"
        />
    </svg>
</template>
