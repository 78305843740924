<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33.657"
        height="33.657"
        viewBox="0 0 33.657 33.657"
    >
        <g id="icon_pop_close" transform="translate(2.828 2.828)">
            <line
                id="Line_137"
                data-name="Line 137"
                x2="28"
                y2="28"
                fill="none"
                stroke="#c5ab7c"
                stroke-linecap="round"
                stroke-width="4"
            />
            <line
                id="Line_138"
                data-name="Line 138"
                x1="28"
                y2="28"
                fill="none"
                stroke="#c5ab7c"
                stroke-linecap="round"
                stroke-width="4"
            />
        </g>
    </svg>
</template>
