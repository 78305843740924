import { CookiesToken } from '@/types/CookiesToken'
import { Dictionary } from '@/types/dictionary'
import { getBrowserLanguage, setCookieValue } from '@/utils'
import Cookies from 'js-cookie'
import { AppState, InjectionKey, State, UserState } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'

import { app } from './modules/app'
import { user } from './modules/user'
import { AppMutation } from './types'
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        version: '1.0.1',
        root: true
    },
    mutations: {
        [AppMutation.CLEAR_ALL](state) {
            const _useState = user.state as UserState
            const _appState = app.state as AppState

            _useState.token = undefined
            _useState.username = undefined
            _useState.userPoint = 0
            _useState.lobyNotice = ''
            _useState.memberid = -1
            _useState.isFreeUser = true
            _useState.commandType = 2
            _useState.userInfo = undefined
            _useState.visitorTable = []
            _useState.clientTimeOut = 5
            _useState.multitable = [0, 0, 0, 0]

            _appState.canPlaySound = 1
            _appState.currentDesk = -1
            _appState.chips = {
                id: 1,
                value: [5, 10, 20, 50, 100, 500, 1000, 10000]
            }

            const lineStr = _appState.lineStr
            const line = _appState.line

            _appState.lang = getBrowserLanguage()

            window.sessionStorage.clear()

            setCookieValue(CookiesToken.LINE, String(line))
            setCookieValue(CookiesToken.LINE_STR, String(lineStr))
        }
    },
    actions: {},
    modules: {
        app,
        user
    }
})

export function useStore(): Store<State> {
    return baseUseStore(key)
}
