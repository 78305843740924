<template>
    <svg
        id="icon_menu_exit"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25.001"
        viewBox="0 0 25 25.001"
    >
        <path
            id="Path_452"
            data-name="Path 452"
            d="M-9134.986-3781a3,3,0,0,1-3-3v-2.5h2v2.5a1,1,0,0,0,1,1H-9121a1,1,0,0,0,1-1v-19a1,1,0,0,0-1-1h-13.988a1,1,0,0,0-1,1v2.5h-2v-2.5a3,3,0,0,1,3-3H-9121a3,3,0,0,1,3,3v19a3,3,0,0,1-3,3Zm-3.651-8.065-4.041-3.738a.99.99,0,0,1-.321-.739.989.989,0,0,1,.328-.737l4.041-3.666a1,1,0,0,1,1.412.069,1,1,0,0,1-.069,1.413l-2.115,1.918h10.07a1,1,0,0,1,1,1,1,1,0,0,1-1,1h-10.123l2.173,2.01a1,1,0,0,1,.058,1.413,1,1,0,0,1-.733.321A.991.991,0,0,1-9138.638-3789.064Z"
            transform="translate(9143 3806)"
            fill="#ffe0a7"
        />
    </svg>
</template>
