
import { giveFeeVerify, operatStatus } from '@/api'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { computed, defineComponent, Emitter, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { iconX } from '@/components/icons'

export default defineComponent({
    components: {
        'icon-x': iconX
    },
    setup() {
        const store = useStore()
        const emitter = inject('emitter') as Emitter

        const isMobile = computed((): boolean => store.getters['isMobile'])
        const { t } = useI18n()

        //#region  Vue Helpers

        //#endregion

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'confirm-booking'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const confirm = () => {
            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString)
            const tableId = urlParams.get('desk')
            if (!tableId) return
            const _params = {
                memberId: memberid.value,
                tableId: tableId,
                status: 3,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            operatStatus(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)

                    if (response.data.status === 100) {
                        emitter.emit(
                            EventBuss.SMALL_TOAST,
                            response.data.description
                        )
                    }
                    close()
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    close()
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompts.networkerror')
                    )
                })
        }

        const cancel = () => {
            close()
        }

        const token = computed(() => store.getters['token'])
        const memberid = computed(() => store.getters['memberid'])

        return {
            isMobile,
            confirm,
            close,
            cancel,
            t
        }
    }
})
