
import { PropType, defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Boolean as PropType<boolean>,
            default: () => true
        }
    },
    setup(props, { emit }) {
        const isOn = ref(true)
        const { t } = useI18n()
        onMounted(() => {
            isOn.value = props.modelValue
        })
        const toggle = () => {
            isOn.value = !isOn.value
            emit('update:modelValue', isOn.value)
        }

        return { isOn, t, toggle }
    }
})
