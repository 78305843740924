<template>
    <svg
        id="icon_lobby_2view_on"
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="27"
        viewBox="0 0 35 27"
    >
        <path
            id="Union_1"
            data-name="Union 1"
            d="M2,27a2,2,0,0,1-2-2V19a2,2,0,0,1,2-2H33a2,2,0,0,1,2,2v6a2,2,0,0,1-2,2ZM2,10A2,2,0,0,1,0,8V2A2,2,0,0,1,2,0H33a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2Z"
            fill="#c5ab7c"
        />
    </svg>
</template>
