<template>
    <svg
        id="icon_login_user"
        xmlns="http://www.w3.org/2000/svg"
        width="29.93"
        height="30.118"
        viewBox="0 0 29.93 30.118"
    >
        <path
            id="Path_513"
            data-name="Path 513"
            d="M122.089,645.834v-2.041c0-4.187-7.422-5.328-7.422-5.328H99.685s-7.525,1.142-7.525,5.328v2.041s-.017,1.54,2.4,1.592,12.629,0,12.629,0,10.155.052,12.577,0S122.089,645.834,122.089,645.834Z"
            transform="translate(-92.16 -617.341)"
            fill="#ffc772"
        />
        <path
            id="Path_514"
            data-name="Path 514"
            d="M109.806,629.76h-2.578l-7.543.156s-7.525,1.3-7.525,5.484v2.041s-.017,1.54,2.4,1.592A10.317,10.317,0,0,0,97,639l7.37-5.605h5.432Z"
            transform="translate(-92.16 -608.931)"
            fill="#e0a03d"
        />
        <path
            id="Path_515"
            data-name="Path 515"
            d="M404.48,501.76h9V507.3h-9Z"
            transform="translate(-393.927 -485.256)"
            fill="#c7928e"
        />
        <path
            id="Path_516"
            data-name="Path 516"
            d="M289.824,248.995a5.016,5.016,0,0,1-1.972,5.588V248.32Zm-17.265,0s-.623,3.495,1.972,5.588V248.32Z"
            transform="translate(-266.4 -240.379)"
            fill="#5d4520"
        />
        <path
            id="Path_517"
            data-name="Path 517"
            d="M332.76,24.75v6.384s1.522,5.5,6.955,5.5S346.6,31.03,346.6,31.03V25.2s.346-8.3-6.885-8.3S332.76,24.75,332.76,24.75Z"
            transform="translate(-324.629 -16.775)"
            fill="#fecccb"
        />
        <path
            id="Path_518"
            data-name="Path 518"
            d="M339.645,36.14c-5.363,0-6.92-5.5-6.92-5.5V24.255s-.346-7.871,6.92-7.871V36.14Z"
            transform="translate(-324.594 -16.28)"
            fill="#d8aeaf"
        />
        <path
            id="Path_519"
            data-name="Path 519"
            d="M278.387,17.931s1.159,4.619,11.885,4.619c0,0-.173-9.238-8.892-9.238s-9,9.238-9,9.238A11.452,11.452,0,0,0,278.387,17.931Z"
            transform="translate(-266.294 -13.312)"
            fill="#5d4520"
        />
        <path
            id="Path_520"
            data-name="Path 520"
            d="M318.246,549.376l-2.854,2.976,4.671,3.46,2.958-2.336Z"
            transform="translate(-307.849 -531.263)"
            fill="#bcbcbc"
        />
        <path
            id="Path_521"
            data-name="Path 521"
            d="M536.743,549.376l2.854,2.976-4.654,3.46-2.976-2.336Z"
            transform="translate(-517.107 -531.263)"
            fill="#eaeaea"
        />
    </svg>
</template>
