
import { ROUTES } from '@/router'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { iconX } from '@/components/icons'

export default defineComponent({
    components: {
        'icon-x': iconX
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const message = ref<string>('')
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()

        onBeforeMount(() => {
            emitter.on(EventBuss.HINT, handleHint)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.HINT, handleHint)
        })

        const handleHint = (_message: string) => {
            message.value = _message
        }

        const callBackFunc = () => {
            emitter.emit(EventBuss.SOCKET_CLOSE)
            store.commit(AppMutation.CLEAR_ALL)
            router.push({ name: ROUTES.LOGIN })

            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'message'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        return { callBackFunc, t, message }
    }
})
