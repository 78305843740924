<template>
    <svg
        id="icon_lobby_name"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
    >
        <path
            id="Path_3"
            data-name="Path 3"
            d="M10,20A10,10,0,1,1,20,10,10,10,0,0,1,10,20ZM10,2.353A4.118,4.118,0,1,0,14.118,6.47,4.117,4.117,0,0,0,10,2.353Zm5.881,11.765a2.94,2.94,0,0,0-2.94-2.941H7.059a2.941,2.941,0,0,0-2.941,2.941v2.446a8.786,8.786,0,0,0,11.764,0Z"
            fill="#fff"
            fill-rule="evenodd"
        />
    </svg>
</template>
