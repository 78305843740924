
import { ChipOption, computed, defineComponent, onMounted, ref } from 'vue'
import { abbreviateNumber } from '@/utils'
import { IconArrowG } from '@/components/icons'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import CtrlBtnClickDbClick from '@/components/ctrl-btn-click-dbclick.vue'
import { useI18n } from 'vue-i18n'
import { dragscroll } from 'vue-dragscroll'

export default defineComponent({
    components: {
        IconArrowG,
        CtrlBtnClickDbClick
    },
    directives: {
        dragscroll
    },
    setup() {
        const { t } = useI18n()
        const store = useStore()
        const editMode = ref<boolean>(false)
        const input = ref<HTMLInputElement>()
        const inputValue = ref<string>()
        let setFocusTimeOut: boolean | number = false
        const scrollNo = ref<number>(0)
        const toShortValue = (value: number) => {
            return abbreviateNumber(value)
        }

        onMounted(() => {
            clearSetfocusTimeout()
        })

        const selectThisChip = (_index: number) => {
            if (_index !== currentChip.value)
                store.commit(AppMutation.SET_CURRENT_CHIPS, _index)
        }

        const customDbClicked = () => {
            editMode.value = true
            clearSetfocusTimeout()
            setFocusTimeOut = setTimeout(() => {
                input.value?.focus()
                clearSetfocusTimeout()
            }, 250)
        }

        const clearSetfocusTimeout = () => {
            if (typeof setFocusTimeOut === 'number') {
                clearTimeout(setFocusTimeOut)
                setFocusTimeOut = false
            }
        }

        const customClicked = () => {
            if (customChip.value > 0) {
                store.commit(AppMutation.SET_CURRENT_CHIPS, 22)
            }
        }

        const onCapchaKeydown = (event: KeyboardEvent) => {
            if (event.key.toLowerCase() !== 'enter') {
                if (event.key.toLowerCase() === 'e') {
                    event.preventDefault()
                    return
                }

                if (
                    document.getSelection()?.toString() ===
                    inputValue.value?.toString()
                ) {
                    return
                }
            }

            if (event.key.toLowerCase() === 'enter') {
                exitEditMode()
            }
        }

        const moveNext = () => {
            if (scrollNo.value! < 1) {
                scrollNo.value++
                console.log(scrollNo.value)
            }
        }
        const moveBack = () => {
            if (scrollNo.value !== 0) {
                scrollNo.value--
                console.log(scrollNo.value)
            }
        }

        const exitEditMode = () => {
            editMode.value = false
            const intValue = parseInt(inputValue.value?.toString() || '')

            if (intValue > 0) {
                store.commit(AppMutation.SET_CUSTOM_CHIP, intValue)
                inputValue.value = ''
                store.commit(AppMutation.SET_CURRENT_CHIPS, 22)
            }
        }

        const chips = computed((): ChipOption => store.getters['chips'])
        const currentChip = computed((): number => store.getters['currentChip'])
        const customChip = computed(() => store.getters['customChip'])

        return {
            toShortValue,
            selectThisChip,
            chips,
            currentChip,
            editMode,
            customChip,
            input,
            inputValue,
            scrollNo,
            customDbClicked,
            customClicked,
            exitEditMode,
            onCapchaKeydown,
            moveNext,
            moveBack,
            t
        }
    }
})
