
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { computed, defineComponent, Emitter, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { iconX } from '@/components/icons'

export default defineComponent({
    components: {
        'icon-x': iconX
    },
    setup() {
        const store = useStore()
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()

        const activeTab = ref<number>(0)

        const isMobile = computed((): boolean => store.getters['isMobile'])

        const close = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'rule'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const confirm = () => {
            emitter.emit(EventBuss.AGREE_TO_AGREEMENT, true)
            close()
        }

        const cancel = () => {
            emitter.emit(EventBuss.AGREE_TO_AGREEMENT, false)
            close()
        }

        return {
            isMobile,
            activeTab,
            confirm,
            close,
            cancel,
            t
        }
    }
})
