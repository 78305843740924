const gamerules = {
    en: {
        baccarat:
            '<h3>Introduction:</h3>' +
            '<p>Baccarat has long been one of the most popular games in Asia, Europe and Latin America. It is simple to play, yet exciting and tense!</p>' +
            '<h3>How to win:</h3>' +
            '<p>In Baccarat, two cards will be dealt, &lt;&lt;Banker&gt;&gt; and &lt;&lt;Player&gt;&gt;. The one with a total of 9 points or the closest to 9 points wins. </p>' +
            '<h3>Operation and betting guide:</h3>' +
            '<ol>' +
            '<li>Click on the chip to bet, then click on any chip on the table to bet (Player, Banker or Tie). </li>' +
            '<li>The player and banker will be dealt two cards, the sum of which is equal to 10 will be regarded as 0 points. If the sum exceeds 9, only the single digits of the total will be counted. </li>' +
            '<li>If any player gets 8 or 9 points (natural winner), the game is over and there will be no more cards. </li>' +
            '<li>After two cards are dealt, if the first two cards of any hand are between 0 and 7, one more card will be dealt according to the supplementing rules, and supplementary cards cannot be chosen at will. </li>' +
            '<li>No hand gets more than three cards. </li>' +
            '</ol>' +
            '<h3>Gameplay:</h3>' +
            '<p>This game uses 8 decks of cards (52 cards in each deck), with a total of 416 cards. The "player" and "dealer" each deal two cards first, with the "player" dealing first. If the first round does not determine the winner, the second round of cards will be dealt according to the "card rules", with a maximum of 3 cards per side. The side closest to 9 points wins, and the same number of points means a tie.</p>' +
            '<h3>Game rules:</h3>' +
            '<ol>' +
            '<li>' +
            'Points Calculation' +
            '<table>' +
            '<thead>' +
            '<tr>' +
            '<td class="size-256 head">Card</td>' +
            '<td class="size-256 head">Points</td>' +
            '</tr>' +
            '</thead>' +
            '<tbody>' +
            '<tr>' +
            '<td>2 to 9</td>' +
            '<td>The number of points to display according to its value</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Ace</td>' +
            '<td>1</td>' +
            '</tr>' +
            '<tr>' +
            '<td>K or Q or J or 10</td>' +
            '<td>0</td>' +
            '</tr>' +
            '</tbody>' +
            '</table>' +
            '<p>' +
            '*When the sum of the first two cards of any family is 8 or 9, it is called a (natural winner). ' +
            '</p>' +
            '<p>' +
            '*After two cards are dealt, if a supplement is needed, one more card will be dealt to the party who needs to supplement according to the rules of the game' +
            '</p>' +
            '<p>Example:</p>' +
            '<p>4 + 2 + 6 = 2</p>' +
            '<p>5 + 6 + 8 = 9</p>' +
            '<p>10 + 10 + 10 = 0</p>' +
            '<br />' +
            '</li>' +
            '<li>' +
            'Drawing rules:' +
            '<table>' +
            '<thead>' +
            '<tr>' +
            '<td class="size-131 head">' +
            'Total points of the two player cards' +
            '</td>' +
            '<td class="size-143 head">闲家</td>' +
            '<td class="size-194 head">' +
            "Total points of the banker's two cards" +
            '</td>' +
            '<td class="size-432 head">Banker</td>' +
            '</tr>' +
            '</thead>' +
            '<tbody>' +
            '<tr>' +
            '<td>0</td>' +
            '<td>Must play</td>' +
            '<td>0</td>' +
            '<td>Must play</td>' +
            '</tr>' +
            '<tr>' +
            '<td>1</td>' +
            '<td>Must play</td>' +
            '<td>1</td>' +
            '<td>Must play</td>' +
            '</tr>' +
            '<tr>' +
            '<td>2</td>' +
            '<td>Must play</td>' +
            '<td>2</td>' +
            '<td>Must play</td>' +
            '</tr>' +
            '<tr>' +
            '<td>3</td>' +
            '<td>Must play</td>' +
            '<td>3</td>' +
            '<td>' +
            'If the player gets a third card with 8 points, the banker cannot win' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>4</td>' +
            '<td>Must play</td>' +
            '<td>4</td>' +
            '<td>' +
            'If the player wins the third card with 0, 1, 8, or 9 points,' +
            'The dealer is not allowed to gamble' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>5</td>' +
            '<td>Must play</td>' +
            '<td>5</td>' +
            '<td>' +
            'If the player wins the third card with 0, 1, 2, 3, 8, 9 points,' +
            'The dealer is not allowed to gamble' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>6</td>' +
            '<td>No gambling</td>' +
            '<td>6</td>' +
            '<td>' +
            'If the player wins the third card is 6 or' +
            '7 points, the dealer must bet' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>8</td>' +
            '<td>The winner is determined by the standard card</td>' +
            '<td>8</td>' +
            '<td>The winner is determined by the standard card</td>' +
            '</tr>' +
            '<tr>' +
            '<td>9</td>' +
            '<td>The winner is determined by the standard card</td>' +
            '<td>9</td>' +
            '<td>The winner is determined by the standard card</td>' +
            '</tr>' +
            '</tbody>' +
            '</table>' +
            '<p>' +
            '* If the banker or player has two cards totaling 8 or 9, the game is decided. The other party does not need to bet and the game is won. If the first two cards of the banker or player each have 6 or 7 points, the game is a tie. ' +
            '</p>' +
            '</li>' +
            '</ol>' +
            '<h3>Payout odds:</h3>' +
            '<p>' +
            "This game provides players with Baccarat and Commission-free Baccarat. The rules and betting types of these two games are the same, but the betting banker 's payout method is different. The betting types and payouts are as follows:" +
            '</p>' +
            '<table>' +
            '<thead>' +
            '<tr>' +
            '<td class="size-256 head">Betting items</td>' +
            '<td class="size-256 head">Odds</td>' +
            '</tr>' +
            '</thead>' +
            '<tbody>' +
            '<tr>' +
            '<td>闲家</td>' +
            '<td>1:1 (bet amount will be refunded if the game ends in a tie)</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Banker</td>' +
            '<td>1:0.95 (bet amount will be refunded if the game ends in a tie)</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Tie</td>' +
            '<td>1:8</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Player Pair</td>' +
            '<td>1:11</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Zhuang Pair</td>' +
            '<td>1:11</td>' +
            '</tr>' +
            '</tbody>' +
            '</table>' +
            '<h3>Banker/Player Pair:</h3>' +
            '<p>' +
            'If the first and second cards of the banker have the same points, it is a banker pair. If the first and second cards of the player have the same points, it is a player pair' +
            '</p>' +
            '<p>Routine definition: The first two cards result in 8 or 9 points</p>' +
            '<p>Non-routine definition: all situations other than routine</p>' +
            '<h3>Please note:</h3>' +
            '<p>' +
            '•' +
            'When the dealer deals the cards, sometimes the system cannot read the situation. At this time, the dealer will rescan until the system can read it completely (if there is a failure factor, all related orders/payouts will be cancelled). ' +
            '</p>' +
            '<p>• In case of settlement error, re-settlement will be made according to the current video results. </p>',
        dragontiger:
            '<h3>Introduction:</h3>' +
            '<p>A gambling game that determines winning or losing based on the number of card points. It is simple and easy to learn, allowing players to fully experience the fun of the casino!</p>' +
            '<h3>How to win:</h3>' +
            '<p>It is correctly estimated that the points of the two players, &lt;&lt;Dragon&gt;&gt; and &lt;&lt;Tiger&gt;&gt;, are relatively large. </p>' +
            '<h3>Operation and Betting Guide:</h3>' +
            '<ol>' +
            '<li>Click on the bet chip, then click on any piece on the table to bet (Dragon, Tiger or Tie). </li>' +
            '<li>The dealer only sends two cards, one card to each door, namely dragon and tiger, and the two sides fight for victory. </li>' +
            '</ol>' +
            '<h3>Gameplay:</h3>' +
            '<ol>' +
            '<li>The cards are compared first, with K being the highest card and A being the smallest. If the points are the same, compare the suits, spades&gt;hearts&gt;clubs&gt;diamond</li>' +
            '<li>The dealer first distributes the first card to the dragon position, and the second card to the tiger position. The one with higher points wins, and the one with the same number and higher suit wins</li>' +
            '</ol>' +
            '<h3>Odds:</h3>' +
            '<table>' +
            '<thead>' +
            '<tr>' +
            '<td class="size-256 head">Betting items</td>' +
            '<td class="size-256 head">Odds</td>' +
            '</tr>' +
            '</thead>' +
            '<tbody>' +
            '<tr>' +
            '<td>Dragon</td>' +
            '<td>1:1 (When the game ends in a tie, all lose)</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Tiger</td>' +
            '<td>1:1 (When the game ends in a tie, all lose)</td>' +
            '</tr>' +
            '<tr>' +
            '<td>and</td>' +
            '<td>1：8</td>' +
            '</tr>' +
            '</tbody>' +
            '</table>' +
            '<h3>Please note:</h3>' +
            '<p>' +
            '•' +
            'When the dealer is dealing cards, occasionally the system cannot interpret the situation. At this time, the dealer will rescan until the system can completely interpret (in case of failure, all related orders/payments will be canceled). ' +
            '</p>' +
            '<p>• If there is a settlement error, settlement will be made again based on the current video results. </p>'
    },
    'zh-cn': {
        baccarat:
            '<h3>简介:</h3>' +
            '<p>长期以来，百家乐是亚洲、欧洲和拉丁美洲最受欢迎的游戏之一，玩法简单，却刺激紧张!</p>' +
            '<h3>如何胜出:</h3>' +
            '<p>百家乐中将发两份牌&lt;&lt;庄家&gt;&gt;和&lt;&lt;闲家&gt;&gt;，总数得9点或最接近9 点的一家胜出。</p>' +
            '<h3>操作及下注指南:</h3>' +
            '<ol>' +
            '<li>点击下注的筹码，再点击桌上下注任何一块(闲家、庄家或平局)。</li>' +
            '<li>闲家和庄家将获发两张牌，加起来等於10作0点，总和超过9，则只算总数中的个位。</li>' +
            '<li>任何一家拿到8或9点（天生赢家），牌局就算结束，不再补牌。</li>' +
            '<li>派出两张牌後，如果任何一手牌的头两张牌的牌面为0至7，将依照补牌规则多发一张牌，不可以任选补牌。</li>' +
            '<li>没有任何一手牌获得超过三张牌。</li>' +
            '</ol>' +
            '<h3>游戏玩法：</h3>' +
            '<p>本游戏采用8副牌（每副牌52张）来进行，游戏牌数合计416张。“闲家”“庄家”各先派两张牌，以“闲家”先发，如第一轮未分出胜负需再按“牌例”发第二轮 的牌，最多每方3张牌，谁最接近9点即为胜方，而相同点数即和局。</p>' +
            '<h3>游戏规则:</h3>' +
            '<ol>' +
            '<li>' +
            '点数计算' +
            '<table>' +
            '<thead>' +
            '<tr>' +
            '<td class="size-256 head">牌面</td>' +
            '<td class="size-256 head">点数</td>' +
            '</tr>' +
            '</thead>' +
            '<tbody>' +
            '<tr>' +
            '<td>2 至 9</td>' +
            '<td>根据其数值显示的点数</td>' +
            '</tr>' +
            '<tr>' +
            '<td>Ace</td>' +
            '<td>1</td>' +
            '</tr>' +
            '<tr>' +
            '<td>K或Q或J或10</td>' +
            '<td>0</td>' +
            '</tr>' +
            '</tbody>' +
            '</table>' +
            '<p>' +
            '*当任何一家头两张牌的点数总和为8或9，就称为(天生赢家)。' +
            '</p>' +
            '<p>' +
            '*派出两张牌後，如果需要补牌，将依照博牌规则向需要补牌方多发一张牌' +
            '</p>' +
            '<p>例子:</p>' +
            '<p>4 + 2 + 6 = 2</p>' +
            '<p>5 + 6 + 8 = 9</p>' +
            '<p>10 + 10 + 10 = 0</p>' +
            '<br />' +
            '</li>' +
            '<li>' +
            '补牌规则:' +
            '<table>' +
            '<thead>' +
            '<tr>' +
            '<td class="size-131 head">' +
            '闲两牌合计点数' +
            '</td>' +
            '<td class="size-143 head">闲家</td>' +
            '<td class="size-194 head">' +
            '庄两牌合计点数' +
            '</td>' +
            '<td class="size-432 head">庄家</td>' +
            '</tr>' +
            '</thead>' +
            '<tbody>' +
            '<tr>' +
            '<td>0</td>' +
            '<td>必须博牌</td>' +
            '<td>0</td>' +
            '<td>必须博牌</td>' +
            '</tr>' +
            '<tr>' +
            '<td>1</td>' +
            '<td>必须博牌</td>' +
            '<td>1</td>' +
            '<td>必须博牌</td>' +
            '</tr>' +
            '<tr>' +
            '<td>2</td>' +
            '<td>必须博牌</td>' +
            '<td>2</td>' +
            '<td>必须博牌</td>' +
            '</tr>' +
            '<tr>' +
            '<td>3</td>' +
            '<td>必须博牌</td>' +
            '<td>3</td>' +
            '<td>' +
            '若闲家博得第三张牌是8点，庄家不得博牌' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>4</td>' +
            '<td>必须博牌</td>' +
            '<td>4</td>' +
            '<td>' +
            '若闲家博得第三张牌是0，1，8，9点，' +
            '庄家不得博牌' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>5</td>' +
            '<td>必须博牌</td>' +
            '<td>5</td>' +
            '<td>' +
            '若闲家博得第三张牌是0，1，2，3，8，9点，' +
            '庄家不得博牌' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>6</td>' +
            '<td>不得博牌</td>' +
            '<td>6</td>' +
            '<td>' +
            '若闲家博得第三张牌是6 或' +
            '7点，庄家必须博牌' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>8</td>' +
            '<td>例牌，即定胜负</td>' +
            '<td>8</td>' +
            '<td>例牌，即定胜负</td>' +
            '</tr>' +
            '<tr>' +
            '<td>9</td>' +
            '<td>例牌，即定胜负</td>' +
            '<td>9</td>' +
            '<td>例牌，即定胜负</td>' +
            '</tr>' +
            '</tbody>' +
            '</table>' +
            '<p>' +
            '*庄闲任何一方两牌合计8、9点为例牌，对方不须博牌，即定胜负。庄闲两方头两张牌各得6或7点，即和局。' +
            '</p>' +
            '</li>' +
            '</ol>' +
            '<h3>派彩赔率：</h3>' +
            '<p>' +
            '本游戏为玩家提供百家乐玩法和免佣百家乐玩法。这两种玩法的博牌规则和投注种类一致，但投注庄的派彩方式有区别。其投注种类与派彩分别如下：' +
            '</p>' +
            '<table>' +
            '<thead>' +
            '<tr>' +
            '<td class="size-256 head">投注项目</td>' +
            '<td class="size-256 head">赔率</td>' +
            '</tr>' +
            '</thead>' +
            '<tbody>' +
            '<tr>' +
            '<td>闲家</td>' +
            '<td>1:1（开和局时退回下注金额）</td>' +
            '</tr>' +
            '<tr>' +
            '<td>庄家</td>' +
            '<td>1:0.95（开和局时退回下注金额）</td>' +
            '</tr>' +
            '<tr>' +
            '<td>和局</td>' +
            '<td>1:8</td>' +
            '</tr>' +
            '<tr>' +
            '<td>闲对子</td>' +
            '<td>1:11</td>' +
            '</tr>' +
            '<tr>' +
            '<td>庄对子</td>' +
            '<td>1:11</td>' +
            '</tr>' +
            '</tbody>' +
            '</table>' +
            '<h3>庄/闲对子:</h3>' +
            '<p>' +
            '庄的第一张和第二张牌点数相同，则为庄对子。闲的第一张和第二张牌点数相同，则为闲对子' +
            '</p>' +
            '<p>例牌定义：首两牌结果为8或9点</p>' +
            '<p>非例牌定义：例牌之外的所有情况</p>' +
            '<h3>请注意 :</h3>' +
            '<p>' +
            '•' +
            '荷官发牌时，偶有系统无法判读状况时，此时荷官将重新扫描，直到系统能够完整判读(如遇故障因素将注销所有相关注单/派彩)。' +
            '</p>' +
            '<p>• 如遇结算错误，按照当期视频结果重新结算。</p>',
        dragontiger:
            '<h3>简介:</h3>' +
            '<p>一个以牌面点数大小来决定输赢的博彩游戏，简单易学，让玩家完全体验赌场的趣味!</p>' +
            '<h3>如何胜出:</h3>' +
            '<p>正确预计两家&lt;&lt;龙&gt;&gt; 和&lt;&lt;虎&gt;&gt;的点数比较大。</p>' +
            '<h3>操作及下注指南:</h3>' +
            '<ol>' +
            '<li>点击下注的筹码，再点击桌上下注任何一块 (龙、虎或平局 )。</li>' +
            '<li>荷官只派两门牌，每门各派一只牌，即龙与虎，双方斗大。</li>' +
            '</ol>' +
            '<h3>游戏玩法：</h3>' +
            '<ol>' +
            '<li>牌面的先比点数，K为最大牌，A为最小。如点数相同，则比较花色，黑桃&gt;红桃&gt;梅花&gt;方块</li>' +
            '<li>荷官先派发第一张牌于龙的位置，而第二张牌派发到虎的位置，点数较大的胜出，点数相同花色大的胜出</li>' +
            '</ol>' +
            '<h3>派彩赔率:</h3>' +
            '<table>' +
            '<thead>' +
            '<tr>' +
            '<td class="size-256 head">投注项目</td>' +
            '<td class="size-256 head">赔率</td>' +
            '</tr>' +
            '</thead>' +
            '<tbody>' +
            '<tr>' +
            '<td>龙</td>' +
            '<td>1 ：1 (开和局时，全输)</td>' +
            '</tr>' +
            '<tr>' +
            '<td>虎</td>' +
            '<td>1 ：1 (开和局时，全输)</td>' +
            '</tr>' +
            '<tr>' +
            '<td>和</td>' +
            '<td>1：8</td>' +
            '</tr>' +
            '</tbody>' +
            '</table>' +
            '<h3>请注意 :</h3>' +
            '<p>' +
            '•' +
            '荷官发牌时，偶有系统无法判读状况时，此时荷官将重新扫描，直到系统能够完整判读(如遇故障因素将注销所有相关注单/派彩)。' +
            '</p>' +
            '<p>• 如遇结算错误，按照当期视频结果重新结算。</p>'
    }
}

export default gamerules
